import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

// Axios
import axios from 'axios';
Vue.prototype.$http = axios;

// Global components
import './globalComponents.js';

// Yandex Metrika
import VueYandexMetrika from "vue-yandex-metrika";
Vue.use(VueYandexMetrika, {
  id: 66610624,
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
    trackHash: true
  }
});

// Global constants
import { globals } from './globalConstants.js';
Vue.prototype.$globals = globals;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
