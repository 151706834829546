export const globals = {
  locale: 'ru-ru',

  phoneRules: [
    (v) => !!v || 'Необходимо указать телефон',
    (v) => (v && v.length == 10) || 'Некорректный формат телефона',
  ],
  emailRules: [
    (v) => !v || /.+@.+\..+/.test(v) || 'Введен некорректный e-mail',
  ],
  loginRules: [
    (v) => !!v || 'Необходимо указать e-mail или телефон',
    (v) =>
      !v ||
      /.+@.+\..+/.test(v) ||
      (v &&
        /^(\+)?((\d{2,3}) ?\d|\d)(([ -]?\d)|( ?(\d{2,3}) ?)){5,12}\d$/.test(
          v
        )) ||
      'Некорректный логин',
  ],
  requiredRule: [
    (v) => {
      if (v instanceof Array) {
        return v.length > 0 || 'Поле является обязательным';
      }
      return !!v || 'Поле является обязательным';
    },
  ],
  isNumberRule: [(v) => /^\d+$/.test(v) || 'Необходимо ввести число'],
  isWordRule: [
    (v) => !v || /^([A-Za-zА-Яа-я -])+$/.test(v) || 'Недопустимые символы',
  ],
  isPromoRule: [
    (v) =>
      !v ||
      /^([A-Za-zА-Яа-я0-9_])+$/.test(v) ||
      'Промокод может содержать только буквы, цифры и символ нижнего подчеркивания',
  ],
  passwordRequiredRule: [(v) => !!v || 'Необходимо ввести пароль'],
  isPasswordRule: [
    (v) =>
      !v ||
      /(?=^.{8,})([A-Za-zА-Яа-я0-9])*$/.test(v) ||
      'Пароль должен содержать минимум 8 символов и состоять только из букв и цифр',
  ],
  lengthLimitRule: [
    (v) => v.length < 33 || 'Превышено ограничение по количеству символов',
  ],

  dateMask: '99.99.9999',
  phoneMask: '+7 (999) 999-9999',
};
