<template>
  <v-app>
    <router-view />
    <idle-warning />
  </v-app>
</template>

<script>
import IdleWarning from '@/components/IdleWarning.vue';

export default {
  name: 'App',
  components: {
    IdleWarning,
  },
  mounted() {
    this.$http.defaults.timeout = 5000;
  },
};
</script>
