const mutations = {
  SET_IS_IDLE(state, val) {
    state.isIdle = val;
  },
  SET_IS_TIMEDOUT(state, val) {
    state.isTimedOut = val;
  },
};

export default mutations;
