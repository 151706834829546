import router from '@/router';
import IdleJs from '@/plugins/idleJs';

var idleTracker = null;
var timeoutTracker = null;

const actions = {
  // Начало и завершение отслеживание активности пользователя
  async startIdleTracker({ state, commit }) {
    timeoutTracker = new IdleJs({
      idle: state.timeOutCountdown,
      onIdle: function() {
        commit('SET_IS_TIMEDOUT', true);
        router.push('/');
      },
    });

    let idleThreshold = state.timeOutThreshold - state.timeOutCountdown;
    idleThreshold = idleThreshold <= 0 ? 5000 : idleThreshold;
    idleTracker = new IdleJs({
      idle: idleThreshold,
      onIdle: function() {
        commit('SET_IS_IDLE', true);
        timeoutTracker.start();
      },
      onActive: function() {
        commit('SET_IS_IDLE', false);
        commit('SET_IS_TIMEDOUT', false);
        timeoutTracker.stop();
      },
    });

    idleTracker.start();
  },
  stopIdleTracker({ commit }) {
    commit('SET_IS_IDLE', false);
    commit('SET_IS_TIMEDOUT', false);
    if (idleTracker) idleTracker.stop();
    if (timeoutTracker) timeoutTracker.stop();
  },
};

export default actions;
