import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '',
    component: () => import('@/layouts/Centered.vue'),
    children: [
      {
        path: '/',
        name: 'main',
        component: () => import('../views/Main.vue'),
      },
      {
        path: '/signup-success',
        name: 'signup-success',
        component: () => import('../views/info/SignUpSuccess.vue'),
      },
    ],
  },
  {
    path: '',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '/signup',
        name: 'signup',
        component: () => import('../views/SignUp.vue'),
      },
      {
        path: '/signin',
        name: 'signin',
        component: () => import('../views/SignIn.vue'),
      },
      {
        path: '/profile',
        name: 'profile',
        component: () => import('../views/Profile.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
