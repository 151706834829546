<template>
  <div class="fullscreen-overlay" v-show="isIdle">
    <div class="fullscreen-overlay-content">
      ВОЗВРАТ НА ГЛАВНУЮ СТРАНИЦУ ЧЕРЕЗ {{ counter }} с
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      counter: this.countdownDuration,
      interval: null,
    };
  },
  computed: {
    isIdle() {
      return this.$store.state.isIdle;
    },
    countdownDuration() {
      return this.$store.state.timeOutCountdown / 1000;
    },
  },
  watch: {
    isIdle: {
      handler(value) {
        if (value) {
          this.interval = setInterval(this.decrementCounter, 1000);
        } else {
          this.counter = this.countdownDuration;
          clearInterval(this.interval);
        }
      },
      immediate: true,
    },
  },
  methods: {
    decrementCounter() {
      this.counter--;
      if (this.counter === 0) {
        clearInterval(this.interval);
      }
    },
  },
};
</script>

<style lang="scss">
.fullscreen-overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 41001;
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
}
.fullscreen-overlay-content {
  position: relative;
  top: 50%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  font-size: 36px;
  color: #d2d2d2;
}
</style>
